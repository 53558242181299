import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { KungBetsIcon, KungHomeIcon, KungInfoIcon, KungRoundIcon, KungSyndicateIcon } from '../images/kungicons';
import { Grid, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { themeC } from '../theme/themehelper';
import { CashIconWhite, HouseIconWhite, TicketIconWhite } from '../reusable-components';

function MobileNavBar({ theme, realMode, agentSyndicateBetting, reportSelection, whichToUnderline }: {
    theme: string, realMode: boolean, agentSyndicateBetting: boolean, whichToUnderline: string
    reportSelection: (type: string, id: string) => void
}) {
    const { t } = useTranslation();

    const isMobileScreen = useMediaQuery('(max-width: 950px)');

    let mobileRoundIcon = KungRoundIcon();
    let mobileBetsIcon = TicketIconWhite(18, 18);
    let mobileSyndicateIcon = CashIconWhite(18, 18);
    let mobileHomeIcon = HouseIconWhite(20, 20);
    let mobileInfoIcon = KungInfoIcon();

    if (theme === themeC.Kung) {
        mobileRoundIcon = KungRoundIcon();
        mobileBetsIcon = KungBetsIcon;
        mobileSyndicateIcon = KungSyndicateIcon;
        mobileHomeIcon = KungHomeIcon;
        mobileInfoIcon = KungInfoIcon();
    }

    

    let gridStyle: SxProps<Theme> = {
        paddingTop: "10px",
        color: "var(--title-bar-color)",
        height: "50px",
        '& > a': { textDecoration: "none" },
        background: 'var(--title-bar-background)',
        flexDirection: "var(--flex-direction)"
    }

    let textStyle: SxProps<Theme> = {
        fontSize: "12px",
        margin: "5px",
        color: "var(--title-bar-color)",
        fontFamily: 'var(--font-title-3)',
    }

    let itemStyle: SxProps<Theme> = {
        flexDirection: "var(--flex-direction)"
    }

    let itemStyleSelected: SxProps<Theme> = {
        flexDirection: "var(--flex-direction)"
    }

    const showIcons = true;

    return (
        <>            
            <Grid sx={gridStyle} container justifyContent="space-evenly">
                <Link to="/clear">
                    <Grid container sx={whichToUnderline === '0' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("home", "mobile_menu")}>
                        {showIcons && mobileHomeIcon}
                        {!showIcons && <Typography sx={textStyle}>Start</Typography>}
                    </Grid>
                </Link>

                <Link to="/roundhistory">
                    <Grid container sx={whichToUnderline === '4' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("rounds", "mobile_menu")}>
                        {showIcons && mobileRoundIcon}
                        <Typography sx={textStyle}>{t('rounds')}</Typography>
                    </Grid>
                </Link>

                {realMode &&
                    <Link to="/mycoupons">
                        <Grid container sx={whichToUnderline === '3' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("coupons", "mobile_menu")}>
                            {showIcons && mobileBetsIcon}
                            <Typography sx={textStyle}>{t('my_bets')}</Typography>
                        </Grid>
                    </Link>
                }

                {agentSyndicateBetting &&
                    <Link to="/playagent">
                        <Grid container sx={whichToUnderline === '2' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("syndicates", "mobile_menu")}>
                            {showIcons && mobileSyndicateIcon}
                            <Typography sx={textStyle}>{t('bet_with_syndicates')}</Typography>
                        </Grid>
                    </Link>
                }

                <Link to="/help">
                    <Grid container sx={whichToUnderline === '6' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("help", "mobile_menu")}>
                        {showIcons && mobileInfoIcon}
                        <Typography sx={textStyle}>{t('help')}</Typography>
                    </Grid>
                </Link>
            </Grid>
        </>
    );

}

export default MobileNavBar;