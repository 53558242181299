import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { StripedShirt } from "../../images/kungicons";
import { FixtureDetail } from "../../models/fixturedetail";
import { Fixture, Team } from "../../models/fixture";
import { IsLeo, IsRightToLeftTheme, themeC } from "../../theme/themehelper";
import useColorThief, { FormatString } from 'use-color-thief';
import kungTShirt from '../../images/tshirt.png';
import { useTranslation } from "react-i18next";

function ScoreBoard({ fixtureDetail, roundFixture, theme }:
    { fixtureDetail: FixtureDetail, roundFixture: Fixture, theme: string }) {

    const home = useColorThief(roundFixture.homeTeam.logoUrl, { format: FormatString.hex })
    const away = useColorThief(roundFixture.awayTeam.logoUrl, { format: FormatString.hex })

    let homeShirtColor;
    let awayShirtColor;

    if (roundFixture.homeTeam.teamColor !== undefined && roundFixture.homeTeam.teamColor !== null && roundFixture.homeTeam.teamColor !== "") {
        homeShirtColor = roundFixture.homeTeam.teamColor
    } else {
        homeShirtColor = home.color
    }

    if (roundFixture.awayTeam.teamColor !== undefined && roundFixture.awayTeam.teamColor !== null && roundFixture.awayTeam.teamColor !== "") {
        awayShirtColor = roundFixture.awayTeam.teamColor
    } else {
        awayShirtColor = away.color
    }

    const { t } = useTranslation();

    let isRightToLeft = IsRightToLeftTheme(theme);

    let shirt = `url(${kungTShirt})`;

    const shirtContainerStyle: SxProps<Theme> = {
        background: shirt,
        width: 70, height: 70,
        backgroundSize: "cover",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 0.6,
        marginTop: "10px"
    }

    let scoreStyle: SxProps<Theme> = {
        whiteSpace: "nowrap",
        margin: "0px 0px 20px 0px",
        fontSize: "34px"
    }

    let teamStyle: SxProps<Theme> = {
        flexDirection: "column",
        padding: "10px 0 0 0",
        alignItems: "center",
        flexWrap: "nowrap",
        whiteSpace: "pre",
        fontWeight: "bold",
        width: "44%",
    }

    let logoStyle: SxProps<Theme> = {
        height: "73px",
        width: "110px",
    }

    let backgroundImageHome = roundFixture.homeTeam.logoUrl ? roundFixture.homeTeam.logoUrl :
        "https://cdn.sportmonks.com/images/soccer/team_placeholder.png";

    backgroundImageHome = `url(${backgroundImageHome})`;

    let backgroundImageAway = roundFixture.awayTeam.logoUrl ? roundFixture.awayTeam.logoUrl :
        "https://cdn.sportmonks.com/images/soccer/team_placeholder.png";

    backgroundImageAway = `url(${backgroundImageAway})`;

    let backGroundStyle: SxProps<Theme> = {
        width: "100%",
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    }

    let teamNameStyle: SxProps<Theme> = {
        fontSize: "14px",
        marginTop: "10px",
        fontWeight: 500
    }

    if (theme === themeC.Kung || theme === themeC.Dbet) {
        scoreStyle = { ...scoreStyle, ...{ fontSize: "50px", fontWeight: 500 } }
        logoStyle = { height: "73px", width: "100px" }
        backGroundStyle = { ...backGroundStyle, ...{ borderRadius: "10px" } }
    }

    const renderScoreAfterFullTime = (fixtureDetail: FixtureDetail) => {

        let { fixture } = fixtureDetail;

        if ((fixture.homeTeam.score !== fixture.homeTeam.eventBasedScore) ||
            (fixture.awayTeam.score !== fixture.awayTeam.eventBasedScore)) {

            if (fixture.homeTeam.eventBasedScore !== -1 && fixture.awayTeam.eventBasedScore !== -1) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontSize: '12px', marginBottom: '4px' }}>{t('after_extra_time')}</div>
                        {isRightToLeft
                            ? <Typography sx={scoreStyle}>{fixture.awayTeam.eventBasedScore} - {fixture.homeTeam.eventBasedScore}</Typography>
                            : <Typography sx={scoreStyle}>{fixture.homeTeam.eventBasedScore} - {fixture.awayTeam.eventBasedScore}</Typography>}
                    </div>
                );
            }
        }

        return <></>;
    }

    function getShirt(color: string, team: Team) {

        if (color === null || color === undefined) {
            color = "#E6E6E6";
        }

        let arms = color;
        let collar = color;
        let stripes = color;
        let base = color;

        if (team.teamKitColors !== undefined && team.teamKitColors.length === 4) {
            base = team.teamKitColors[0];
            stripes = team.teamKitColors[1];
            arms = team.teamKitColors[2];
            collar = team.teamKitColors[3];
        }

        if (team.teamKitColors !== undefined && team.teamKitColors.length === 11) {
            base = team.teamKitColors[1];
            arms = team.teamKitColors[0];
            collar = team.teamKitColors[10];
            stripes = team.teamKitColors[2];
        }

        return StripedShirt(58, 60, darkenColor(base, -20), arms, collar, base, stripes)

    }

    function darkenColor(color: string, percent: number) {

        if (color === null || color === undefined) {
            return color;
        }

        // Strip the leading # if it's there
        color = color.replace(/^\s*#|\s*$/g, "");

        // Convert 3 char codes -> 6, e.g. `E0F` -> `EE00FF`
        if (color.length === 3) {
            color = color.replace(/(.)/g, "$1$1");
        }

        // Split HEX Color
        const hexR = color.substring(0, 2);
        const hexG = color.substring(2, 4);
        const hexB = color.substring(4, 6);

        // HEX to RGB
        let r = parseInt(hexR, 16);
        let g = parseInt(hexG, 16);
        let b = parseInt(hexB, 16);

        if (isNaN(r)) r = 0;
        if (isNaN(g)) g = 0;
        if (isNaN(b)) b = 0;

        // Manipulate
        const newR = Math.min(255, Math.floor(r + (r * percent) / 100));
        const newG = Math.min(255, Math.floor(g + (g * percent) / 100));
        const newB = Math.min(255, Math.floor(b + (b * percent) / 100));

        // RGB to HEX
        const newHexRColor = `${newR.toString(16)}`.padStart(2, "0");
        const newHexGColor = `${newG.toString(16)}`.padStart(2, "0");
        const newHexBColor = `${newB.toString(16)}`.padStart(2, "0");

        return "#" + newHexRColor + newHexGColor + newHexBColor;
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ height: "243px", padding: "0 16px" }}>
            <Grid container direction="row" justifyContent="space-between" flexWrap="nowrap" sx={{ width: "70%", maxWidth: "600px", flexDirection: 'var(--flex-direction)' }}>
                <Grid container sx={teamStyle}>
                    {(theme === themeC.Kung || theme === themeC.Dbet || IsLeo(theme)) && !roundFixture?.homeTeam?.isNationalTeam && !roundFixture?.awayTeam?.isNationalTeam ?
                        <Grid container sx={{ ...shirtContainerStyle, ...{ background: '' } }}>
                            {/* {KungShirtIcon(58, 60, homeShirtColor as string)} */}
                            {getShirt(homeShirtColor as string, roundFixture.homeTeam)}
                        </Grid> :
                        <Grid sx={logoStyle}>
                            <Box sx={{ ...{ background: backgroundImageHome, backgroundSize: roundFixture?.homeTeam?.isNationalTeam ? "cover" : "contain" }, ...backGroundStyle }} />
                        </Grid>
                    }
                    <Typography sx={teamNameStyle}>{roundFixture.homeTeam.name}</Typography>
                </Grid>

                <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ height: "130px" }}>
                    {isRightToLeft
                        ? <Typography sx={scoreStyle}>{roundFixture.awayTeam.score} - {roundFixture.homeTeam.score}</Typography>
                        : <Typography sx={scoreStyle}>{roundFixture.homeTeam.score} - {roundFixture.awayTeam.score}</Typography>}
                    {fixtureDetail && renderScoreAfterFullTime(fixtureDetail)}
                </Grid>

                <Grid container sx={teamStyle}>
                    {(theme === themeC.Kung || theme === themeC.Dbet || IsLeo(theme)) && !roundFixture?.homeTeam?.isNationalTeam && !roundFixture?.awayTeam?.isNationalTeam ?
                        <Grid container sx={{ ...shirtContainerStyle, ...{ background: '' } }}>
                            {/* {KungShirtIcon(58, 60, awayShirtColor as string)} */}
                            {getShirt(awayShirtColor as string, roundFixture.awayTeam)}
                        </Grid> :
                        <Grid sx={logoStyle}>
                            <Box sx={{ ...{ background: backgroundImageAway, backgroundSize: roundFixture?.awayTeam?.isNationalTeam ? "cover" : "contain" }, ...backGroundStyle }} />
                        </Grid>
                    }
                    <Typography sx={teamNameStyle}>{roundFixture.awayTeam.name}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ScoreBoard;