export const TRANSLATIONS_FR = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",
    
    my_bets: "Mes paris",
    my_coupons: "Mes coupons",
    rounds: "Tours",    
    bet_with_syndicates: "Syndicates",
    
    syndicates: "Syndicats",
    turnover: "Pool actuel",
    
    single_rows: "Rangées simples",
    pro_pick: "Choix de pro",
    often: "Gagnez souvent",
    a_lot: "Gagnez beaucoup",
    system: "Système",
    row_distribution: "Répartition des lignes",
    overview: "Aperçu",
    full_cover: "Couverture intégrale",
    half_cover: "Demi-couverture",
    row_price: "Prix de la ligne",
    pay: "Placer un pari",
    
    unsettled_coupons: "Coupons non réglés",
    settled_coupons: "Coupons réglés",
    stake: "Pari",
    coupon_share_status_cancelled: "Annulé",
    your_winnings_right_now: "Vos gains dès maintenant",
    correct_rows_now: "Faire des choix corrects maintenant",
    starts: "Commence",
    correct_rows: "Lignes correctes",
    prize: "Prix",
    your_rows: "Vos lignes",
    profit: "Bénéfice",
    st: "",
    total: "Résultat",
    rows: "lignes",
    row: "ligne",
    price_per_row: "Prix ​​par ligne",
    total_cost: "Coût total",
    paid: "Payé",
    settled: "Réglé",
    channel_internet: "Chaîne : Internet",
    print: "Imprimer",
    share: "Partager",    
    clear_bets: 'Mises claires',

    
    reduce: "Réduire",
    no_reduced_systems_available: "Pas de systèmes réduits disponibles",
    round_history: "Historique des rondes",
    
    no_profit: "Pas de profit",
    no_coupons: "Pas de coupons",
    started: "Commencé à",
    round: "Rond",
    winners: "Gagnants",
    your_bet_is_paid: "Votre pari a été placé!",
    do_you_want_to_place_bet: "Voulez-vous placer un pari de",
    
    highest_win: "Gain le plus élevé",
    insufficient_funds: "Fonds insuffisants",
    cancel: "Annuler",
    unable_to_complete_purchase: 'Impossible de finaliser l’achat',
    error_code: 'Code d’erreur',

    event_goal: 'But',
    event_penalty: 'Pénalité',
    event_missed_penalty: 'Penalty manqué',
    event_own_goal: 'But contre son camp',
    event_var: 'VAR',
    event_yellowcard: 'Carton jaune',
    event_yellowred: 'Deuxième carton jaune',
    event_substitution: 'Substitution',
    event_redcard: 'Carton rouge',
    event_pen_shootout_goal: 'But sur penalty',
    event_pen_shootout_miss: 'Penalty manqué',
    event_full_time: 'Temps plein',
    event_half_time: 'Mi-temps',
    event_kickoff: 'Kickoff',
    event_finished_after_extra: 'Prolongation terminée',

    out: 'Dehors',

    
    table: "Table",
    game_info: "Informations sur le jeu",
    statistics: "Statistiques",
        
    team_name: "Équipe",
    table_abbreviation_num_games: "G",
    table_abbreviation_wins: "W",
    table_abbreviation_draws: "D",
    table_abbreviation_losses: "L",
    table_abbreviation_points: "P",

    round_active_after_deadline: 'Fermé',
    full_time: "Temps plein",
    round_status_finished : "Fini",
    half_time: 'Mi-temps',
    
    current_rounds: "Rondes en cours",
    settled_rounds: "Rondes réglées",
    no_rounds_available: "Pas de rondes disponibles",

    row_statistics: 'Statistiques de ligne',
    
    showing: 'Montrant',    
    settings: 'Paramètres',
    match: 'Match',
    result: 'Résultat',    
    bet_type: "Type de pari",    
    mathematical_system: "Système mathématique",
    reduced_system: "Système réduit",
    no_system: "Pas de système",
    select: "Choisir",
    is_draw: "Sera tiré au sort avec",
    is_drawn: "Dessiné",
    draw_disclaimer: 'En cas de tirage au sort:',
    num_correct_win: 'Nombre correct/gagnant',
    more_info: 'Plus infos',
    in_running: 'En cours d’exécution',
  
    
    go_back: 'Retour',

    to_syndicate: "Syndiquer",
    shares: "Actions",
    share_price: "Cours de l’action",
    sold_shares: "Actions vendues",
    agent_admin: "Administrateur de l’agent",
    agents: "Agents",
    to_agent: "À l’agent",
    create_new_syndicate: "Créer un nouveau syndicat",
    name: "Nom",
    target_number_of_shares: "Nombre cible d’actions",
    max_number_of_shares: "Nombre maximal d’actions",
    submit_syndicate: "Soumettre un syndicat",
    update_syndicate: "Mettre à jour le syndicat",
    show_sv_percent: 'Afficher le pourcentage de joueurs.',
    show_coupon_percent: 'Afficher le coupon %',
    number_of_rows: 'Nombre de lignes',
    share_of_syndicate: 'Part du syndicat',
    syndicate_created: 'Création d’un syndicat',
    syndicate_submitted: 'Syndicat soumis',
    syndicate_updated: 'Mise à jour du syndicat',
    finished: 'Fini',
    started_games: 'Commencé',
    potential: 'Potentiel',
    sort_by_finished: 'Trier par fini',
    game_summary: 'Résumé du match',
    date: 'Date',
    league: 'Ligue',
    venue: 'Lieu',
    latest_tweets: 'Derniers tweets de',
    weather: 'Temps',
    temperature: 'Température',
    wind: 'Vent',
    moisture: 'Humidité',
    general_info: 'Informations générales',
    locale: 'fr-MA',
    show: 'Montrer',
    show_round: 'Visite d’exposition',
    play_on: 'Jouez sur',
    today: 'Aujourd’hui',
    latest_games: 'Derniers jeux',
    head_to_head: 'Face à facev',
    round_information_short: 'Info',
    round_information: 'Informations sur les rondes',
    prize_groups: 'Groupes de prix',
    bet: 'Parier',
    pro_pick_bets: 'Pari choix de pro',
    help: 'Aide',
    round_status_open : "Ouvert",
    round_status_voided : "Annulé",
    round_status_paused : "Pause",
    round_paused_explanation: 'Le tour est temporairement suspendu pour les paris',
    jackpot : "Estimation du jackpot",
    after_extra_time: "Après prolongation",
    system_guarantee: "Garantie correcte",
    reduced_disclaimer:"Dans un système réduit, le nombre de lignes du système mathématique correspondant a été réduit. Vous avez moins de chances de gagner, mais le système coûte également moins cher.",
    show_rows:"Afficher mes lignes",
    api_betting:"Téléchargement de fichiers",
    select_file:"Sélectionner un fichier",
    file:"Ficher",
    id:"Id",
    upload:"Télécharger",
    bet_title:"Parier",
    follow_title:"Suivre",
    win_title:"Gagner",
    bet_text:"Choisissez un ou plusieurs résultats dans chacun des matchs de football de la manche",
    follow_text:"Suivez vos gains en direct sur le site",
    win_text:"Si vous avez choisi suffisamment de bons résultats, vous serez l’un des gagnants de la poule",
    share_of_prizepool: "Part de la cagnotte",
    register: "Registre",
    fee_per_row: "Frais par ligne",
    bet_status_open: "Ouvert",
    bet_status_won: "Gagné",
    bet_status_lost: "Perdu",
    payout: "Paiement",
    show_details: "Afficher les détails",
    bet_now: "Pariez maintenant",
    place_more: "Si vous voulez augmenter vos chances de gagner, vous pouvez choisir plus d’un résultat dans chaque jeu",
    active_syndicates: "Syndicats actifs",
    settled_syndicates: "Syndicats réglés",
    win_multiplier: "Multiplicateur de gains",
    win_multiplier_information: "Votre mise et votre gain potentiel sont multipliés par le multiplicateur de gain sélectionné",
    status: "Statut",
    balance: "Balance",

    join_title:"Join the group",
    join_text:"Take part in a bigger system together with other players and increase your chances of winning the jackpot.",
    valid_title:"Always valid",
    valid_text:"Your bet is always valid, even if the syndicate is not sold out.",
    win_together_title:"Win together",
    win_together_text:"The winnings from the syndicate are split between the shares.",
    fee:"Fee",
    error_title:"Something went wrong",
    error_text:"Please try refreshing the page, or go back to the homepage.",
}
